//
// Inputs Flavor
// --------------------------------------------------

@input-border-focus: @accent;
@input-color-placeholder: @secondary;
// default input
input.form-control,
textarea.form-control,
select.form-control {
  border-color: @input-border;
  box-shadow: inset 0px 0px 0px 1px @input-border;
  box-shadow: none;
  font-weight: 500;
  .input-md();
  height: min-content !important;
  line-height: 130% !important;

  &:focus {
    border-color: @input-border-focus;
    box-shadow: none;
  }
}

.input-sm () {
  padding: 11px 8px !important;
  font-size: 14px !important;
  height: min-content;
}

.input-md () {
  padding: 13px 11px;
  font-size: 16px;
  height: min-content;
}

.input-md-size {
  .input-md();
}
.input-sm-size {
  .input-sm();
}

@media (max-width: 767px) {
  &.input-md-size {
    .input-sm();
  }
}

.has-error input.form-control {
  border-color: @state-danger-border;
  box-shadow: inset 0px 0px 0px 1px @state-danger-border;

  &:focus {
    border-color: lighten(@state-danger-border, 20%);
    box-shadow: inset 0px 0px 0px 1px lighten(@state-danger-border, 20%);
  }
}

.has-success input.form-control {
  border-color: @state-success-border;
  box-shadow: inset 0px 0px 0px 1px @state-success-border;

  &:focus {
    border-color: lighten(@state-success-border, 20%);
    box-shadow: inset 0px 0px 0px 1px lighten(@state-success-border, 20%);
  }
}

// password strenght marker
.password-strength {
  margin-top: @line-height-computed / 2;

  .text-small {
    margin-top: @line-height-computed / 5;

    color: lighten(@text-color, 25%);
    font-size: @font-size-small;
  }

  .progress {
    margin: 0px;
  }
}
