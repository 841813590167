//
// Poll
// --------------------------------------------------

.panel-poll h2 {
  margin-top: 0px;
}

.poll-select-choice .btn {
  &,
  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border: transparent;
    .box-shadow(none);

    margin: @padding-base-vertical 0px;
    padding: 0px;
    padding-left: @padding-base-vertical;

    outline: none;

    text-align: left;
  }

  .material-icon {
    margin-right: @padding-base-vertical;

    height: 28px;
    width: 28px;

    font-size: 28px;
    line-height: 28px;

    color: darken(@gray-lighter, 10%);
  }

  &.btn-selected .material-icon {
    color: @brand-primary;
  }
}

.poll-help {
  font-size: @font-size-small;
}

.poll-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
}

.poll-chart-selected .material-icon {
  margin-right: 4px;

  position: relative;
  bottom: 1px;

  height: 14px;
  width: 14px;

  color: @brand-success;
  font-size: 14px;
  line-height: 14px;
}

.poll-options {
  margin-bottom: 0px;
  @media screen and (max-width: @screen-sm-max) {
    margin-top: @padding-base-vertical * -1;

    .btn {
      margin: @padding-base-vertical 0px;
    }
  }
}
