//
// Buttons Flavor
// --------------------------------------------------

// mixins
.misago-btn(@color, @bg, @color-hover, @bg-hover, @color-active, @bg-active) {
  &,
  &:disabled,
  &:disabled:hover,
  &.disabled,
  &.disabled:hover,
  &.disabled:active {
    background: @bg;
    border: 1px solid @bg;

    color: @color;

    .box-shadow(none);
  }

  &:disabled,
  &:disabled:hover,
  &.disabled,
  &.disabled:hover,
  &.disabled:active {
    .opacity(0.25);
  }

  &:hover,
  &:focus {
    background: @bg-hover;
    border: 1px solid @bg-hover;

    color: @color-hover;

    .box-shadow(none);
  }

  &:active:focus,
  &:active,
  .dropdown.open .dropdown-toggle &,
  .dropdown.open .dropdown-toggle &:focus,
  .dropdown.open .dropdown-toggle &:active:focus,
  .dropdown.open .dropdown-toggle &:hover {
    background: @bg-active;
    border: 1px solid @bg-active;

    color: @color-active;

    .box-shadow(none);
  }

  &.btn-loading {
    &,
    &:disabled,
    &:disabled:hover,
    &:hover,
    &:focus,
    &:active:focus,
    &:active {
      color: transparent;
    }
  }
}

.misago-btn-outline(@color, @bg, @border,
                    @color-hover, @bg-hover, @border-hover,
                    @color-active, @bg-active, @border-active) {
  &,
  &:disabled,
  &:disabled:hover,
  &.disabled,
  &.disabled:hover,
  &.disabled:active {
    background: @bg;
    border: 1px solid @border;

    color: @color;

    .box-shadow(none);
  }

  &:disabled,
  &:disabled:hover,
  &.disabled,
  &.disabled:hover,
  &.disabled:active {
    .opacity(0.25);
  }

  &:hover,
  &:focus {
    background: @bg-hover;
    border: 1px solid @border-hover;

    color: @color-hover;

    .box-shadow(none);
  }

  //TODO:
  &:active:focus,
  &:active
  // .dropdown.open &,
  // .dropdown.open &:focus,
  // .dropdown.open &:active:focus,
  // .dropdown.open &:hover
  {
    background: @bg-active;
    border: 1px solid @border-active;

    color: @color-active;

    .box-shadow(none);
  }

  &.btn-loading {
    &,
    &:disabled,
    &:disabled:hover,
    &:hover,
    &:focus,
    &:active:focus,
    &:active {
      background: @bg;
      border: 1px solid @border;

      color: transparent;

      .box-shadow(none);
    }
  }
}

// button styles
.btn-default {
  .misago-btn(
    @btn-default-color,
    @btn-default-bg,

    @btn-default-hover-color,
    @btn-default-hover-bg,

    @btn-default-active-color,
    @btn-default-active-bg
  );
}

.btn-primary {
  .misago-btn(
    @btn-primary-color,
    @btn-primary-bg,

    @btn-primary-hover-color,
    @btn-primary-hover-bg,

    @btn-primary-active-color,
    @btn-primary-active-bg
  );
}

// .btn-secondary {
//   .misago-btn(
//     @btn-primary-color,
//     @border-bg-light,

//     @btn-primary-hover-color,
//     @btn-primary-hover-bg,

//     @btn-primary-active-color,
//     @btn-primary-active-bg
//   );
// }

.btn-accent {
  .misago-btn(
    @primary,
    @accent,

    @primary-hover,
    @accent-hover,

    @primary-hover,
    @accent-hover,
  );
}

.btn-success {
  .misago-btn(
    @btn-success-color,
    @btn-success-bg,

    @btn-success-hover-color,
    @btn-success-hover-bg,

    @btn-success-active-color,
    @btn-success-active-bg
  );
}

// .btn-secondary {
//   .misago-btn(
//     @primary,
//     @border-bg-light,

//     @btn-success-hover-color,
//     @btn-success-hover-bg,

//     @btn-success-active-color,
//     @btn-success-active-bg
//   );
// }

.btn-danger {
  .misago-btn(
    @btn-danger-color,
    @btn-danger-bg,

    @btn-danger-hover-color,
    @btn-danger-hover-bg,

    @btn-danger-active-color,
    @btn-danger-active-bg
  );
}

// outlined button styles
.btn-default.btn-outline {
  .misago-btn-outline(
    @btn-default-outline-color,
    @btn-default-outline-bg,
    @btn-default-outline-border,

    @btn-default-outline-hover-color,
    @btn-default-outline-hover-bg,
    @btn-default-outline-hover-border,

    @btn-default-outline-active-color,
    @btn-default-outline-active-bg,
    @btn-default-outline-active-border
  );
}

.btn-primary.btn-outline {
  .misago-btn-outline(
    @btn-primary-outline-color,
    @btn-primary-outline-bg,
    @btn-primary-outline-border,

    @btn-primary-outline-hover-color,
    @btn-primary-outline-hover-bg,
    @btn-primary-outline-hover-border,

    @btn-primary-outline-active-color,
    @btn-primary-outline-active-bg,
    @btn-primary-outline-active-border
  );
}

.btn-secondary.btn-outline {
  .misago-btn-outline(
    @primary,
    @border-bg-light,
    @border-bg-medium,

    @primary,
    @border-bg-medium,
    @border-bg-medium,

    @primary,
    @border-bg-medium,
    @border-bg-medium,
  );
}

//sizes button
//large
@btn-padding-lg-vertical: 13px;
@btn-padding-lg-horizontal: 19px;
@btn-font-size-lg: 16px;

//medium
@btn-padding-base-vertical: 11px;
@btn-padding-base-horizontal: 15px;
@btn-font-size-base: 14px;

//small
@btn-padding-xs-vertical: 7px;
@btn-padding-xs-horizontal: 11px;
@btn-font-size-xs: 12px;

@btn-border-radius: 4px;

.btn-sm() {
  .button-size(@btn-padding-base-vertical; @btn-padding-base-horizontal; @btn-font-size-base; @line-height-small; @btn-border-radius);
}

// Миксин для btn-lg
.btn-lg() {
  .button-size(@btn-padding-lg-vertical; @btn-padding-lg-horizontal; @btn-font-size-lg; @line-height-large; @btn-border-radius);
}

// Миксин для btn-xs
.btn-xs() {
  .button-size(@btn-padding-xs-vertical; @btn-padding-xs-horizontal; @btn-font-size-xs; @line-height-small; @btn-border-radius);
}

.btn-lg {
  .btn-lg();
}

.btn-sm {
  .btn-sm();
}

.btn-xs {
  .btn-xs();
}

@media (max-width: 767px) {
  .btn {
    &.btn-lg {
      .btn-sm();
    }
    // &.btn-sm {
    //   .btn-xs();
    // }
  }
}
