//
// Material Icons
// --------------------------------------------------

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/MaterialIcons-Regular.woff2) format("woff2"),
    url(../fonts/MaterialIcons-Regular.woff) format("woff"),
    url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: local("Ubuntu"), local("Ubuntu-Regular"),
    url(../fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: local("Ubuntu"), local("Ubuntu-Medium"),
    url(../fonts/Ubuntu-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: local("Ubuntu"), local("Ubuntu-Bold"),
    url(../fonts/Ubuntu-Bold.ttf) format("truetype");
}

.material-icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
  vertical-align: middle;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
