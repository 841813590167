//
// Events Flavor
// --------------------------------------------------

// Style label
.posts-list .event .event-label {
  margin-bottom: @line-height-computed / 4;
}

.posts-list .event .label-unread {
  background-color: @post-unread-label;
  color: #000;
  font-weight: 400;
}

// Style info text
.posts-list .event-info {
  a,
  a:link,
  a:visited,
  .btn-link {
    color: @secondary;
  }

  a:focus,
  a:active,
  a:focus:active,
  .btn-link:focus,
  .btn-link:active,
  .btn-link:focus:active {
    color: @text-color;
  }
}

// Fade out hidden event
.posts-list .event-hidden {
  .opacity(0.33);
}
