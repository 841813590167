//
// Forum Footer
// --------------------------------------------------

// Superbasic spacing and layout for easy overriding
.misago-footer {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid #e4e4e3;
  grid-area: f;
  .footer-content {
    border-top: 1px solid @page-header-border-color;
    padding-top: @line-height-computed * 1.5;
  }
}

// Position "enable JS!" message's icon
.misago-footer .noscript-message .material-icon {
  position: relative;
  bottom: 1px;

  font-size: @font-size-large;
}

//CUSTOM STYLES FOOTER

.footer-container {
  display: grid;
  grid-template-areas: "c n l" "c s l";
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: repeat(2, 1fr);
  gap: 24px;

  .footer-logo {
    width: 180px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
  }
}

//768px
@media screen and (max-width: @screen-xs-max) {
  .footer-logo {
    width: 136px !important;
    height: 20px !important;
  }
}

@media screen and (max-width: 580px) {
  .footer-logo {
    width: 92px !important;
    height: auto !important;
  }
}

@media screen and (max-width: @screen-md-min) {
  .footer-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: min-content min-content min-content;
    grid-template-areas: "c c c c" "n n n n" "s s s l";
  }
}

@media screen and (max-width: @screen-xs-max) {
  .footer-desktop-link {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 24px !important;
    & > li > a {
      font-size: 16px !important;
    }
  }
}

.footer-desktop-link {
  gap: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  & > li > a {
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    color: #000;
    text-decoration: none;
  }
}

@media screen and (max-width: @screen-xs-max) {
  .misago-footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.footer-desktop-sublist {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;

  & > li > a {
    font-size: 12px;
    font-weight: 400;
    white-space: break-spaces;
    color: gray;
    text-decoration: none;
  }
}
