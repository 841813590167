//
// Forms Flavor
// --------------------------------------------------

.panel-form {
  border: 1px solid @panel-form-border;
  box-shadow: none;
}

.panel-form .panel-heading,
.panel-form .panel-footer {
  border-color: @panel-form-border;
}

// remove bottom margin from poll panel
.poll-form .panel {
  margin: 0px;
}

// Override help text color
.help-block,
.help-block .text-small {
  color: @secondary;
}
