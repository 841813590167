@spacer: 8px;

.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: @spacer !important;
}
.m-2 {
  margin: @spacer * 2 !important;
}
.m-3 {
  margin: @spacer * 3 !important;
}
.m-4 {
  margin: @spacer * 4 !important;
}
.m-5 {
  margin: @spacer * 5 !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: @spacer !important;
}
.mr-1 {
  margin-right: @spacer !important;
}
.mb-1 {
  margin-bottom: @spacer !important;
}
.ml-1 {
  margin-left: @spacer !important;
}
.mx-1 {
  margin-left: @spacer !important;
  margin-right: @spacer !important;
}
.my-1 {
  margin-top: @spacer !important;
  margin-bottom: @spacer !important;
}

.mt-2 {
  margin-top: @spacer * 2 !important;
}
.mr-2 {
  margin-right: @spacer * 2 !important;
}
.mb-2 {
  margin-bottom: @spacer * 2 !important;
}
.ml-2 {
  margin-left: @spacer * 2 !important;
}
.mx-2 {
  margin-left: @spacer * 2 !important;
  margin-right: @spacer * 2 !important;
}
.my-2 {
  margin-top: @spacer * 2 !important;
  margin-bottom: @spacer * 2 !important;
}

.mt-3 {
  margin-top: @spacer * 3 !important;
}
.mr-3 {
  margin-right: @spacer * 3 !important;
}
.mb-3 {
  margin-bottom: @spacer * 3 !important;
}
.ml-3 {
  margin-left: @spacer * 3 !important;
}
.mx-3 {
  margin-left: @spacer * 3 !important;
  margin-right: @spacer * 3 !important;
}
.my-3 {
  margin-top: @spacer * 3 !important;
  margin-bottom: @spacer * 3 !important;
}

.mt-4 {
  margin-top: @spacer * 4 !important;
}
.mr-4 {
  margin-right: @spacer * 4 !important;
}
.mb-4 {
  margin-bottom: @spacer * 4 !important;
}
.ml-4 {
  margin-left: @spacer * 4 !important;
}
.mx-4 {
  margin-left: @spacer * 4 !important;
  margin-right: @spacer * 4 !important;
}
.my-4 {
  margin-top: @spacer * 4 !important;
  margin-bottom: @spacer * 4 !important;
}

.mt-5 {
  margin-top: @spacer * 5 !important;
}
.mr-5 {
  margin-right: @spacer * 5 !important;
}
.mb-5 {
  margin-bottom: @spacer * 5 !important;
}
.ml-5 {
  margin-left: @spacer * 5 !important;
}
.mx-5 {
  margin-left: @spacer * 5 !important;
  margin-right: @spacer * 5 !important;
}
.my-5 {
  margin-top: @spacer * 5 !important;
  margin-bottom: @spacer * 5 !important;
}

.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}
