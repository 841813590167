//
// Post Feed
// --------------------------------------------------

.post-feed .post-side {
  width: 100%;
  padding-right: 0;

  .media-heading {
    margin: 0px;

    font-size: @font-size-base;
  }

  .user-title {
    margin: 0px;

    font-size: 14px;
  }
}

.post-feed .post-side .btn {
  display: inline-block;
}

.post-feed .post-side img {
  margin-top: 0px;
}

.post-feed .post-heading {
  height: auto;
  margin: @line-height-computed / 2 0px;
}

.post-feed .post-heading .btn {
  margin-right: @padding-large-horizontal;
  max-width: 100%;

  text-align: left;
  white-space: normal;
  overflow-wrap: anywhere;
}
