.react-datepicker-wrapper {
  font-weight: 500;
  > div {
    border: 1px solid @border-bg-medium;
    border-radius: 4px;
    background-color: transparent;
    text-align: start;
    line-height: 130%;

    span {
      color: @secondary;
    }
    .material-icon {
      position: absolute;
      top: 50%;
      transform: translate(0px, -50%);
      right: 5px;
      cursor: pointer;
    }
    input {
      line-height: 130%;
      border: none;
      outline: none;
      width: 100%;
    }
  }
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: @accent-light !important;
  color: #000 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-start {
  background-color: @accent !important;
}

// Стили для дня, который является текущей датой
.react-datepicker__day[aria-current="date"] {
  background: #f6f6f6 !important;
  color: #000;
  font-weight: 700 !important;
}

// Стили для текущего дня, который также выбран
.react-datepicker__day[aria-current="date"][aria-selected="true"] {
  background-color: @accent;
}

// Стили для выбранного дня
.react-datepicker__day[aria-selected="true"] {
  background-color: @accent;
  color: #000;
}

// Стили для невыбранных, но доступных дней при наведении
.react-datepicker__day:hover[aria-selected="false"][aria-disabled="false"] {
  background-color: #f6f6f6 !important;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--keyboard-selected {
  background-color: @accent !important;
  color: #000 !important;
}

// Стили для заголовка контейнера
.react-datepicker__header--custom {
  background-color: #fff !important;
  border: none !important;
  padding: 0 !important;
}

// Стили для контейнера с днями недели
.react-datepicker__day-names {
  margin-bottom: 0px !important;
  background: #f6f6f6;
  border-top: 1px solid #e4e4e3;
  border-bottom: 1px solid #e4e4e3;
  padding: 0px 10px;
}

// Стили для месяца
.react-datepicker__month {
  margin: 0 !important;
  padding: 4px 10px 12px 10px;
}
