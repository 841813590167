//
// Posts List Post
// --------------------------------------------------

@media screen and (min-width: @screen-md-min) {
  .panel-post .panel-body {
    display: flex;
  }

  .post-side {
    flex-shrink: 0;
    width: @post-side-width;
    padding-right: @post-side-padding;
  }

  .panel-content {
    flex: 1 0;
  }
}

// Post side
.post-side {
  .media {
    margin: 0px;
  }

  .poster-avatar {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    @media screen and (min-width: @screen-md-min) {
      margin-top: 4px;

      height: 82px;
      width: 82px;
    }
  }

  .user-status {
    margin-right: 4px;
  }

  // .media-heading {
  //   display: block;
  // }

  .media-heading {
    display: flex;
    align-items: center;
    margin: 0px;
    margin-top: -1px;

    font-size: @font-size-base;

    .user-status {
      margin-right: 0;
      margin-left: 2px;
    }
  }

  .pull-right {
    margin-left: @padding-large-horizontal;

    & > button {
      aspect-ratio: 1/1;
    }
  }

  // Lay user details vertically
  @media screen and (min-width: @screen-md-min) {
    .media-heading {
      margin-top: 3px;

      font-size: @font-size-large;

      .user-status {
        display: none;
      }
    }

    .user-title {
      margin-top: 4px;
      margin-bottom: 5px;
    }

    .user-postcount {
      display: block;
    }

    .user-status {
      display: block;
    }
  }
}

// Heading
.post-heading {
  height: 36px;
  color: @secondary;

  @media screen and (max-width: @screen-sm-max) {
    margin-top: @line-height-computed / 2;
  }

  .pull-right {
    margin-left: @padding-large-horizontal;
    & > button {
      aspect-ratio: 1/1;
    }
  }

  & > .btn-link {
    padding-left: 0px;
    padding-right: 0px;
  }

  .label {
    margin-top: 6px;

    font-size: @font-size-base;
    font-weight: normal;
  }

  .label-unread {
    background-color: @brand-success;

    color: #fff;
  }

  .label-protected {
    background-color: @gray;

    color: #fff;
  }
}

// Move controls up
@media screen and (min-width: @screen-md-min) {
  .post-side .pull-right {
    display: none;
  }
}

@media screen and (max-width: @screen-sm-max) {
  .post-heading .pull-right {
    display: none;
  }
}

// Body
.post-body {
  padding-top: @line-height-computed;
  padding-bottom: @line-height-computed * 1.5;
}

// Status
.post-status-message {
  overflow: auto;

  .material-icon {
    float: left;

    font-size: 28px;
    line-height: 28px;
  }

  p {
    margin: 0px;
    margin-top: (28px - @line-height-computed) / 2;
    margin-left: 36px;
  }
}

.post-status-best-answer {
  background-color: @brand-success;

  color: #fff;
}

.post-status-hidden {
  background-color: @brand-danger;

  color: #fff;
}

.post-status-unapproved {
  background-color: @brand-primary;

  color: #fff;
}

.post-status-protected {
  background-color: @gray;

  color: #fff;
}

// Footer
.post-footer {
  .pull-left {
    margin-right: @padding-large-horizontal;
  }

  .pull-right {
    margin-left: @padding-large-horizontal;
  }

  p {
    padding-top: 7px;
    padding-bottom: 6px;
    margin-bottom: 0px;
  }
}

// Attachments
.post-attachments {
  @post-preview-width: 40px;

  border-top: 1px solid @gray-lighter;
  padding: @padding-base-vertical @padding-large-horizontal;

  abbr {
    white-space: nowrap;
  }

  .attachment-name {
    overflow-wrap: anywhere;
  }

  .row > div {
    margin: @padding-large-vertical 0px;
  }

  .post-attachment-preview {
    float: left;
    height: @post-preview-width;
    width: @post-preview-width;

    text-align: center;
  }

  .post-attachment {
    margin-left: @post-preview-width + 12px;
  }

  .post-thumbnail {
    display: block;
    background-size: cover;
    background-position: center;
    border-radius: @border-radius-small;
    width: @post-preview-width;
    height: @post-preview-width;
  }

  .material-icon {
    width: 28px;
    height: 28px;

    position: relative;
    top: 5px;

    font-size: 28px;
    line-height: 28px;

    &:link,
    &:active,
    &:visited,
    &:hover,
    &:focus {
      color: @gray-darker;
      text-decoration: none;
    }
  }

  .post-attachment-description {
    margin: 0px;
    padding: 0px;

    color: @text-muted;
    font-size: @font-size-small;
  }
}
