//
// Progress Bars Flavor
// --------------------------------------------------

// Make progress bars flat
.progress,
.progress .progress-bar {
  .box-shadow(none);

  height: 4px;
}
