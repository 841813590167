//
// Search dropdown and overlay
// --------------------------------------------------

.search-dropdown {
  width: 358px;
  padding: 0;
  overflow: hidden;
}

.search-dropdown .search-input {
  padding: 16px 12px;
  border-bottom: 1px solid @dropdown-divider-bg;
}

// .search-input input {
//   padding: 8px 12px;
//   font-size: 12px;
// }

.search-dropdown .list-group {
  max-height: calc(100vh - @dropdown-height-offset - 46px);
  overflow-y: auto;
}

.search-overlay-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.search-overlay-body .search-input {
  padding: @overlay-padding;

  border-bottom: 1px solid @dropdown-divider-bg;
}

// Make font size on search input bigger to disable zoom on iOS
.search-dropdown .form-control-search,
.search-overlay-body .form-control-search {
  font-size: @search-control-font-size;
  padding: 8px 12px;
}

.search-overlay-body .search-results-container {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  position: relative;
}

.search-overlay-body .search-results-list {
  position: absolute;
  width: 100%;
  min-height: 100%;
}

// Search results
.search-result a {
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }

  img {
    border-radius: @border-radius-small;
    margin-right: 12px;
  }
}

// User search result
.search-result-card {
  width: 100%;
}

.search-result-name {
  color: @text-color;
  font-weight: bold;
  overflow-wrap: anywhere;
}

.search-result-summary {
  max-height: @font-size-small * 6;
  overflow: hidden;
  overflow-wrap: anywhere;

  color: @secondary;
  font-size: @font-size-small;
}

.search-result-details {
  margin: 0;
  padding: 0;

  color: @secondary;
  font-size: @font-size-small;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 12px;
  }
}
